// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField, TextFieldProps } from '@mui/material';
import { Box, Stack, Typography } from '@mui/material';
import NumberFormat from 'react-number-format';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import useResponsive from 'src/hooks/useResponsive';

// ----------------------------------------------------------------------

type IProps = {
  name: string;
};

type Props = IProps & TextFieldProps;


export default function RHFMoneyTextField({ name, ...other }: Props) {
  const { control } = useFormContext();
  const isMobile = useResponsive('down', 'sm');

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Stack direction="row">
        <Box borderRadius={'8px 0px 0px 8px !important'} justifyItems={'center'} justifyContent={'center'} sx={{ width: '92px', backgroundColor:"#1D4E82", display:'flex', alignItems:'center'}}>
          <Typography alignContent={'center'} textAlign="center" color="white" >
            R$
          </Typography>
        </Box>
          <NumericFormat
            {...field}
            fullWidth
            inputMode="numeric"
            sx={{
              maxWidth: '386px',
              maxHeight: '62px',
              minWidth: isMobile ? '70vw' : '386px',
              '& .MuiOutlinedInput-notchedOutline': {
                borderRadius: '0px 8px 8px 0px !important',
              },
              '& .MuiInputBase-input': {
                textAlign: 'center',
                pr: '62px',
              },
            }}
            customInput={TextField}
            placeholder='Valor do bônus'
            thousandSeparator="."
            decimalSeparator=","
            allowNegative={false}
            inputProps={{ maxLength: 10, inputMode: 'numeric' }}
            suffix=",00"
          />
        {/* <TextField
          {...field}
          fullWidth
          type="number"
          value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
          error={!!error}
          helperText={error?.message}
          {...other}
          /> */}
        </Stack>
      )}
    />
  );
}
