import { Suspense, lazy, ElementType } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import LoadingScreen from '../components/LoadingScreen';
import StoreGuard from 'src/guards/StoreGuard';
import PromoterGuard from 'src/guards/PromoterGuard';
import StoreLayout from 'src/layouts/StoreLayout';
import ManualInsert from 'src/pages/store/pages/manualInsert/ManualInsert'; 
import InsertCode from 'src/pages/store/pages/insertCode/InsertCode'; 
import ConsultList from 'src/pages/store/pages/ConsultList';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <Navigate to="/promoter/login" replace />,
    },
    {
      path: '/promoter',
      children: [
        { element: <Navigate to="/promoter/login" replace />, index: true },
        { path: 'area', element: <PromoterGuard><PromoterArea /></PromoterGuard> },
        { path: 'login', element: <PromoterLogin /> },
        { path: 'client', element: <PromoterGuard><ClientCellphone /></PromoterGuard> },
        { path: 'client-pin', element: <PromoterGuard><ClientPin /></PromoterGuard> },
        { path: 'bonus', element: <PromoterGuard><CreateBonus /></PromoterGuard> },
        { path: 'experiences-list', element: <PromoterGuard><ExperiencesList /></PromoterGuard> },
        { path: 'experience/:experienceId', element: <PromoterGuard><ExperienceDetail /></PromoterGuard> },
        { path: 'experience-access/:experienceId', element: <PromoterGuard><ExperienceAccess /></PromoterGuard> },
      ],
    },
    {
      path: '/store',
      children: [
        { element: <Navigate to="/store/login" replace />, index: true },
        { path: 'login', element: <StoreLogin /> },
      ]
    },
    {
      path: '/store',
      element: <StoreGuard><StoreLayout /></StoreGuard>,
      children: [
        { element: <Navigate to="/store/area" replace />, index: true },
        { path: 'area', element: <StoreArea /> },
        { path: 'collectible', element: <CollectibleRescue /> },
        { path: 'manual-insert', element: <ManualInsert /> },
        { path: 'insert-code-coupon', element: <InsertCode /> },
        { path: 'insert-code-collectible', element: <InsertCode /> },
        { path: 'consult-coupon', element: <ConsultList /> },
        { path: 'consult-collectible', element: <ConsultList /> },
      ],
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// Dashboard
const PromoterLogin = Loadable(lazy(() => import('../pages/promoter/pages/PromoterLogin')));
const PromoterArea = Loadable(lazy(() => import('../pages/promoter/pages/PromoterArea')));
const ClientCellphone = Loadable(lazy(() => import('../pages/promoter/pages/ClientCellphone')));
const ClientPin = Loadable(lazy(() => import('../pages/promoter/pages/ClientPin')));
const CreateBonus = Loadable(lazy(() => import('../pages/promoter/pages/CreateBonus')));
const ExperiencesList = Loadable(lazy(() => import('../pages/promoter/pages/ExperiencesList')));
const ExperienceDetail = Loadable(lazy(() => import('../pages/promoter/pages/ExperienceDetail')));
const ExperienceAccess = Loadable(lazy(() => import('../pages/promoter/pages/ExperienceAccess')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));

// Store
const StoreLogin = Loadable(lazy(() => import('../pages/store/pages/StoreLogin')));
const StoreArea = Loadable(lazy(() => import('../pages/store/pages/StoreArea')));
const CollectibleRescue = Loadable(lazy(() => import('../pages/store/pages/CollectibleRescue')));