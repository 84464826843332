// @mui
import { List, Box } from '@mui/material';
//
import { NavSectionProps } from '../type';
import NavList from './NavList';

// ----------------------------------------------------------------------

export default function NavSectionVertical({ navConfig, isCollapse, ...other }: NavSectionProps) {
  return (
    <Box {...other}>
      {navConfig.map((group, idx) => (
        <List key={'SHOW_'+idx} disablePadding>
          {group.items.map((list) => (
            <NavList
              key={list.title + list.path}
              data={list}
              depth={1}
              hasChildren={!!list.children}
              isCollapse={isCollapse}
            />
          ))}
        </List>
      ))}
    </Box>
  );
}
