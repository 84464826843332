import { yupResolver } from "@hookform/resolvers/yup";
import { useSnackbar } from "notistack";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { redeemCollectible, redeemCoupon, resetInfo, verifyCollectibleInfo, verifyCouponInfo } from "src/redux/slices/store";
import { useDispatch } from "src/redux/store";
import * as Yup from 'yup';

type FormValuesProps = {
    code: any;
  };

export default function useCollectibleRescue() {
    const dispatch = useDispatch()
    const { pathname } = useLocation()
    const { enqueueSnackbar } = useSnackbar()
    const [ showInfo, setShowInfo ] = useState(false)
    const [ code, setCode ] = useState('')
    const [ success, setSuccess ] = useState(false)

    const StoreSchema = Yup.object().shape({
        code: Yup.string(),
	});

	const defaultValues = useMemo(() =>(
      {
        code: '',
      }
    ),[]);

	const methods = useForm<FormValuesProps>({
		resolver: yupResolver(StoreSchema),
		defaultValues: defaultValues
	});

    const {
		watch,
		handleSubmit,
        reset,
		formState: { errors, isSubmitting },
	} = methods;

    const values = watch()

    const onVerifyCode = async (result: any) => {
        if(result){
            setCode(result.code)
            try{
                if(pathname.includes('coupon')){
                    await dispatch(verifyCouponInfo(result.code))
                }else{
                    await dispatch(verifyCollectibleInfo(result.code))
                }
                setShowInfo(true)
            }catch(e){
                enqueueSnackbar(e.response?.data?.message || e.message, { variant: 'error' })
                setShowInfo(false)
            }
        }
    }

    const handleRedeem = async () => {
        try{
            if(pathname.includes('coupon')){
                await dispatch(redeemCoupon(code))
            }else{
                await dispatch(redeemCollectible(code))
            }
            setSuccess(true)
        }catch(e){
            enqueueSnackbar(e.response?.data?.message || e.message, { variant: 'error' })
        }
    }

    const handleCancelVerify = () => {
        setShowInfo(false)
        setCode('')
        reset()
        setSuccess(false)
        dispatch(resetInfo())
    }

    const handleGoToStart = () => {
        window.location.reload()
        // setShowInfo(false)
        // setCode('')
        // setSuccess(false)
        // dispatch(resetInfo())
    }

    return {
        code,
        values,
        errors,
        methods,
        success,
        showInfo,
        isSubmitting,
        setCode,
        onVerifyCode,
        handleSubmit,
        handleRedeem,
        handleCancelVerify,
        handleGoToStart
    }
}
  