import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
// hooks
// ----------------------------------------------------------------------

type StoreGuardProps = {
  children: ReactNode;
};

export default function StoreGuard({ children }: StoreGuardProps) {
  if (!localStorage.getItem('@cnStore-name')) {
    return <Navigate to={'/store/login'} />
  }

  return <>{children}</>;
}
