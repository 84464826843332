import { MouseEventHandler } from 'react'
import { buttonStyle } from './style'
import { LoadingButton } from '@mui/lab'

type ButtonProps = {
	title?: string,
	textColor?: any,
	backgroundColor?: any,
	onClick?: MouseEventHandler<HTMLButtonElement> | undefined,
	disabled?: boolean,
	type?: any,
    isLoading?: boolean
	sx?:any
}

export default function ContainedLoadingButton({ title, textColor, backgroundColor, onClick, disabled = false, type = 'button', isLoading = false, sx }: ButtonProps) {

	return <LoadingButton 
		fullWidth
        loading={isLoading} 
		variant='contained' 
		disabled={disabled}
		type={type}
		sx={{ 
			...buttonStyle, 
			color: textColor, 
			backgroundColor: backgroundColor,
			":hover": {
				color: textColor, 
				backgroundColor: backgroundColor,
			},
			...sx
		}} 
		onClick={disabled ? () => {} : onClick}
	>
		{title}
	</LoadingButton>
}
