import jwtDecode from 'jwt-decode';
//
import axios from './axios';

// ----------------------------------------------------------------------

const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode<{ exp: number }>(accessToken);

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

// const handleTokenExpired = (exp: number) => {
//   let expiredTimer;

//   const currentTime = Date.now();

//   // Test token expires after 10s
//   // const timeLeft = currentTime + 10000 - currentTime; // ~10s
//   const timeLeft = exp * 1000 - currentTime;

//   clearTimeout(expiredTimer);

//   expiredTimer = setTimeout(() => {
//     alert('Token expired');

//     localStorage.removeItem('@cnPromoter-accessToken');

//     window.location.href = PATH_AUTH.register
//   }, timeLeft);
// };

const setSession = (accessToken: string | null, refreshToken: string | null) => {
  if (accessToken && refreshToken) {
    localStorage.setItem('@cnPromoter-accessToken', accessToken);
    localStorage.setItem('@cnPromoter-refreshToken', refreshToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    // This function below will handle when token is expired
    // const { exp } = jwtDecode<{ exp: number }>(accessToken); // ~3 days by minimals server
    // handleTokenExpired(exp);
  } else {
    localStorage.removeItem('@cnPromoter-accessToken');
    localStorage.removeItem('@cnPromoter-refreshToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

const setSessionStore = (accessToken: string | null, refreshToken: string | null) => {
  if (accessToken && refreshToken) {
    localStorage.setItem('@cnStore-accessToken', accessToken);
    localStorage.setItem('@cnStore-refreshToken', refreshToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    // This function below will handle when token is expired
    // const { exp } = jwtDecode<{ exp: number }>(accessToken); // ~3 days by minimals server
    // handleTokenExpired(exp);
  } else {
    localStorage.removeItem('@cnStore-accessToken');
    localStorage.removeItem('@cnStore-refreshToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

export { isValidToken, setSession, setSessionStore };
