import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// import axios from '../../utils/axios';
import { post } from './api';
import { AxiosError } from 'axios';

type ClientState = {
  isLoading: boolean,
  error: any,
  data: any,
  cellphoneFormData: any,
  isError: boolean,
  description: string,
  title: string,
  pin: any,
}

const initialState: ClientState = {
  isLoading: false,
  error: null,
  data: null,
  cellphoneFormData: null,
  isError: false,
  description: '',
  title: '',
  pin: null
};

const slice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setModalInfos(state, action) {
      state.description = action.payload.description;
      state.title = action.payload.title;
      state.isError = action.payload.isError;
    },

    setPhoneFormData(state, action) {
      state.cellphoneFormData = action.payload
    },

    resetPin(state) {
      state.pin = null
    },

    getPinSuccess(state, action){
      state.isLoading = false;
      state.pin = action.payload;
    }
  },
});

export default slice.reducer;

export const { setPhoneFormData, resetPin } = slice.actions;

export function sendPin(phone: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const numbers = phone?.replace(/\D/g, "");
      let result = numbers.substring(2);
      localStorage.setItem('@cnUser-phone', result);
      await post('/promoter/send-pin', { "phone": result });
      dispatch(slice.actions.stopLoading());
      return result;
    } catch (error) {
      dispatch(slice.actions.stopLoading());
      let errorMessage = "Erro no servidor";
      if (error instanceof AxiosError && error.response?.status === 404
        && error.response?.data?.message === 'userNotRegisteredInViva') {
        dispatch(slice.actions.setModalInfos(
          {
            title: "Atenção! Cliente não cadastrado no Viva Center Norte.",
            description: "Auxilie o cliente a se cadastras no Viva pelo app Center Norte com o telefone informado.",
            isError: true
          }
        ));
        errorMessage = "Cliente não cadastrado no Viva Center Norte";
      } else {
        dispatch(slice.actions.setModalInfos(
          {
            title: "Atenção! Ocorreu um erro ao enviar o código.",
            description: "Tente novamente mais tarde.",
            isError: true
          }
        ));
      }
      var helper = {
        message: errorMessage,
        hasError: true
      }
      return helper
    }
  };
}


export function getPin(phone: string){
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const numbers = phone?.replace(/\D/g, "");
      let result = numbers.substring(2);
      const response = await post('/promoter/get-pin', { "phone": result });
      dispatch(slice.actions.getPinSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.stopLoading());
    }
  };
}