// components
// import { PATH_DASHBOARD } from '../../../routes/paths';

// ----------------------------------------------------------------------

const navConfig = [
  {
    items: [
      { 
        title: 'Consultar cupons', 
        path: '/store/consult-coupon', 
      },
      { 
        title: 'Consultar produtos', 
        path: '/store/consult-collectible', 
      },
      { 
        title: 'Extrair dados', 
        path: 'coupons-pdf', 
      },
    ]
  },
];

export default navConfig;
