import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import Page from "src/components/Page";
import useConsultList from "../hooks/useConsultList";
import { useSelector } from "src/redux/store";

export default function ConsultList(){
    const { pathname } = useLocation()
    useConsultList()
    const { list, isLoadingList } = useSelector((state) => state.store)

    return(
        <Page title='teste'>
            <Stack pb={10}>
                <Box
                    mt='52px'
                    p={5}
                    bgcolor='#F2F2F2'
                    width='100vw'
                    borderRadius='0px 0px 80px 80px'
                    position='fixed'
                    zIndex={90}
                    justifyContent='center'
                    alignItems='center'
                    display='flex'
                >
                    <Typography variant='h6' color='primary.main'>
                        {pathname.includes('coupon') ? 'Cupons' : 'Produtos'}
                    </Typography>
                </Box>
                <Box height='190px' />
                {isLoadingList ? 
                    <Box flexGrow={1} display='flex' justifyContent='center' pt={20}>
                        <CircularProgress/>
                    </Box>
                    :
                    list.length === 0 ?
                    <Typography textAlign='center'>
                        Nenhum resultado encontrado
                    </Typography>
                    :
                    <Stack spacing={4} alignItems='center' mx={4}>
                        {list.map((item: any, idx: number) => 
                            <Stack alignItems='center' spacing={2} key={'LIST'+idx} width='100%'>
                                <Typography variant="subtitle1" textAlign='center'>
                                    {item.name}
                                </Typography>
                                <Stack direction='row' justifyContent='space-between' width='100%'>
                                    <Stack alignItems='center' spacing={0.5}>
                                        <Typography variant='subtitle2'>
                                            {item.total}
                                        </Typography>
                                        <Typography variant='caption' color='text.secondary'>
                                            Quantidade
                                        </Typography>
                                    </Stack>
                                    <Stack alignItems='center' spacing={0.5}>
                                        <Typography variant='subtitle2'>
                                            {item.used}
                                        </Typography>
                                        <Typography variant='caption' color='text.secondary'>
                                            Resgatados
                                        </Typography>
                                    </Stack>
                                    <Stack alignItems='center' spacing={0.5}>
                                        <Typography variant='subtitle2'>
                                            {item.redeemed}
                                        </Typography>
                                        <Typography variant='caption' color='text.secondary'>
                                            Reservados
                                        </Typography>
                                    </Stack>
                                    <Stack alignItems='center' spacing={0.5}>
                                        <Typography variant='subtitle2'>
                                            {item.available}
                                        </Typography>
                                        <Typography variant='caption' color='text.secondary'>
                                            Disponíveis
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Stack>
                        )}
                    </Stack>
                }
            </Stack>
        </Page>
    )
}