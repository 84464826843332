import { Button, Dialog, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import SuccessIcon from "src/components/icons/SuccessIcon";

type Props = {
    isCoupon: boolean;
    isOpen: boolean;
    isManualInsertion?: boolean;
    onGoToStart: () => void;
}

export default function SuccessModal({
    isCoupon,
    isOpen,
    isManualInsertion=false,
    onGoToStart
}: Props){
    const navigate = useNavigate()
    return(
        <Dialog open={isOpen} PaperProps={{ sx: { borderRadius: '40px !important' } }}>
            <Stack py={5} px={3} spacing={3} alignItems='center' width='100%'>
                <SuccessIcon/>
                <Typography variant='subtitle1' color='primary.main' textAlign='center'>
                    {isManualInsertion ? 'Liberação executada com sucesso' : isCoupon ? 'Cupom resgatado com sucesso' : 'Viva Presente resgatado com sucesso'}
                </Typography>
                <Button
                    fullWidth
                    sx={{
                        borderRadius: 5,
                        textTransform: 'none',
                        p: 1.5
                    }}
                    variant='contained'
                    onClick={() => onGoToStart()}
                >
                    {isManualInsertion ? 'Fazer outra liberação' : isCoupon ? 'Resgatar outro cupom' : 'Resgatar outro viva presente'}
                </Button>
                <Button
                    fullWidth
                    sx={{
                        borderRadius: 5,
                        textTransform: 'none',
                    }}
                    onClick={() => navigate('/store/area')}
                >
                    Voltar ao começo 
                </Button>
            </Stack>
        </Dialog>
    )
}