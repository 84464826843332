import InputMask from 'react-input-mask';
import { useFormContext, Controller } from 'react-hook-form';
import { TextField, TextFieldProps } from '@mui/material';
interface IProps {
  name: string;
  mask: string;
}
//@ts-nocheck
//@ts-ignore
export default function RHFMaskField({ name, mask, ...other }: IProps & TextFieldProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <InputMask  maskChar="" mask={mask} onChange={field.onChange} onBlur={field.onBlur}>
            {
            //@ts-ignore
            (inputProps: TextFieldProps) => <TextField error={!!error} helperText={error?.message} {...inputProps} fullWidth {...other}/>
            }
        </InputMask>
      )}
    />
  );
}
