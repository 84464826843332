import { useFormContext, Controller } from 'react-hook-form';
import { Checkbox, FormControlLabel, FormGroup, FormControlLabelProps, Stack, TextField, Typography } from '@mui/material';
import { useState } from 'react';

interface RHFMultiCheckboxProps extends Omit<FormControlLabelProps, 'control' | 'label'> {
  name: string;
  options: {
    label: string | JSX.Element ;
    value: any;
  }[];
}

export default function RHFMultiCheckbox({ name, options, ...other }: RHFMultiCheckboxProps) {
  const { control } = useFormContext();
  const [ otherValue, setOtherValue ] = useState('')

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        field.value = field.value ?? [];
        const onSelected = (option: string) => {
          setOtherValue('')
          if(field.value.includes(option)){
            return field.value.filter((value: string) => value !== option)
          }else{
            return [option]
          }
        }

        return (
          <FormGroup>
            {options.map((option) => (
              <Stack direction='row' key={option.value} alignItems='center'>
              <FormControlLabel
                control={
                    <Checkbox
                      checked={field.value.includes(option.value)}
                      onChange={() => field.onChange(onSelected(option.value))}
                    />                }
                label={option.label}

                {...other}
              />
              {option.value === 'Outro' &&
                <TextField 
                  disabled={!field.value.includes('Outro')}
                  variant='standard'
                  value={otherValue}
                  onChange={(e) => { 
                    setOtherValue(e.target.value)
                    field.onChange([option.value, e.target.value])
                  }}
                />
              }
              </Stack>
            ))}
            {error &&
              <Typography color='error.main' variant='caption' mt={1}>
                {error.message}
              </Typography>
            }
          </FormGroup>
        );
      }}
    />
  );
}