import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
import { get, post } from './apiStore';
import moment from 'moment';

type PromoterState = {
  isLoading: boolean,
  isLoadingRedeem: boolean,
  isLoadingInfo: boolean,
  isLoadingList: boolean,
  error: any,
  collectibleDetails: any,
  infoDetails: any,
  list: any
}

const initialState: PromoterState = {
  isLoading: false,
  isLoadingRedeem: false,
  isLoadingInfo: false,
  isLoadingList: false,
  error: null,
  collectibleDetails: null,
  infoDetails: null,
  list: []
};

const slice = createSlice({
  name: 'store',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    startLoadingRedeem(state) {
      state.isLoadingRedeem = true;
    },

    startLoadingInfo(state) {
      state.isLoadingInfo = true;
    },

    startLoadingList(state) {
      state.isLoadingList = true;
    },

    stopLoading(state) {
      state.isLoading = false;
    },

    stopLoadingRedeem(state) {
      state.isLoadingRedeem = false;
    },

    stopLoadingInfo(state) {
      state.isLoadingInfo = false;
    },

    stopLoadingList(state) {
      state.isLoadingList = false;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    redeemSuccess(state, action){
      state.isLoadingRedeem = false;
      state.collectibleDetails = action.payload;
    },

    infoSuccess(state, action){
      state.isLoadingInfo = false;
      state.infoDetails = action.payload;
    },

    listSuccess(state, action){
      state.isLoadingList = false;
      state.list = action.payload;
    },

    resetInfo(state){
      state.isLoadingInfo = false;
      state.infoDetails = null;
    },

    resetList(state){
      state.isLoadingList = false;
      state.list = [];
    },
  },
});

export default slice.reducer;

export const { resetInfo, resetList } = slice.actions;

export function verifyStore(code: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      var response = await post('/storeApp/verify', { storeCode: code });
      dispatch(slice.actions.stopLoading());
      return response.data
    } catch (error) {
      dispatch(slice.actions.stopLoading());
      throw error
    }
  };
}

export function verifyCollectibleInfo(code: string){
  return async () => {
    dispatch(slice.actions.startLoadingInfo());
    try {
      var response = await get(`/storeApp/redeemed-collectible-user-data/${code}`);
      dispatch(slice.actions.infoSuccess({
        name: response?.data?.collectibleId?.name,
        reservedDate: moment(response?.data?.reservationDate).format('DD/MM/YYYY'),
        clientName: response?.data?.userId?.name,
        clientPhone: response?.data?.userId?.phone
      }));
    } catch (error) {
      dispatch(slice.actions.stopLoadingInfo());
      throw error
    }
  }
}

export function verifyCouponInfo(code: string){
  return async () => {
    dispatch(slice.actions.startLoadingInfo());
    try {
      var response = await get(`/storeApp/redeemed-coupon-user-data/${code}`);
      dispatch(slice.actions.infoSuccess({
        name: response?.data?.couponName,
        reservedDate: moment(response?.data?.redeemedAt).format('DD/MM/YYYY'),
        clientName: response?.data?.clientName,
        clientPhone: response?.data?.clientPhone
      }));
    } catch (error) {
      dispatch(slice.actions.stopLoadingInfo());
      throw error
    }
  }
}

export function redeemCollectible(code: string) {
  return async () => {
    dispatch(slice.actions.startLoadingRedeem());
    try {
      var response = await post('/collectible/redeem', { code });
      dispatch(slice.actions.redeemSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.stopLoadingRedeem());
      throw error
    }
  };
}

export function redeemCoupon(code: string) {
  return async () => {
    dispatch(slice.actions.startLoadingRedeem());
    try {
      var response = await post('/newCupons/use-coupon-storeapp', { couponCode: code });
      dispatch(slice.actions.redeemSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.stopLoadingRedeem());
      throw error
    }
  };
}

export function manualInsert(body: any) {
  return async () => {
    dispatch(slice.actions.startLoadingRedeem());
    try {
      var response = await post('/storeApp/manual-insertion', body);
      dispatch(slice.actions.redeemSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.stopLoadingRedeem());
      throw error
    }
  };
}

export function getCoupons() {
  return async () => {
    dispatch(slice.actions.startLoadingList());
    try {
      var response = await get('/storeApp/coupon-status-count');
      dispatch(slice.actions.listSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.stopLoadingList());
      throw error
    }
  };
}

export function getCollectibles() {
  return async () => {
    dispatch(slice.actions.startLoadingList());
    try {
      var response = await get('/storeApp/collectible-status-count');
      dispatch(slice.actions.listSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.stopLoadingList());
      throw error
    }
  };
}