import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

function Menu({ ...other }: BoxProps) {
  return (
    <Box {...other} alignContent='center' display='flex'>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="4" cy="12" r="1" fill="white"/>
        <rect x="7" y="11" width="14" height="2" rx="0.94" fill="white"/>
        <rect x="3" y="16" width="18" height="2" rx="0.94" fill="white"/>
        <rect x="3" y="6" width="18" height="2" rx="0.94" fill="white"/>
        </svg>
    </Box>
  );
}

export default Menu;