import { Typography, Stack, Box } from "@mui/material";
import Page from "src/components/Page";
import ContainedLoadingButton from "src/components/buttons/ContainedLoadingButton";
import { useSelector } from "src/redux/store";
import useResponsive from "src/hooks/useResponsive";
import { FormProvider, RHFTextField } from "src/components/hook-form";
import VerificationForm from "../components/VerificationForm";
import useCode from "../../hooks/useCode";
import SuccessModal from "../components/SuccessModal";
import { useLocation } from "react-router-dom";
import LoadingIcon from "src/components/icons/LoadingIcon";

export default function InsertCode() {
  const isMobile = useResponsive('down', 'sm');
  const {
    values,
    methods,
    showInfo,
    success,
    onVerifyCode,
    handleSubmit,
    handleRedeem,
    handleCancelVerify,
    handleGoToStart
  } = useCode();
  const { pathname } = useLocation()
  const { isLoading } = useSelector((state) => state.store);
  const { isLoadingInfo, infoDetails, isLoadingRedeem } = useSelector((state) => state.store)

  return (
    <Page title={pathname.includes('coupon') ? 'Resgatar Cupom' : 'Resgatar Viva Presente'}>
      <Stack>
        <Box
          mt='52px'
          p={5}
          bgcolor='#F2F2F2'
          width='100vw'
          borderRadius='0px 0px 80px 80px'
          position='fixed'
          zIndex={90}
          justifyContent='center'
          alignItems='center'
          display='flex'
        >
          <Typography variant='h6' color='primary.main'>
            {pathname.includes('coupon') ? 'Resgatar Cupom' : 'Resgatar Viva Presente'}
          </Typography>
        </Box>
        <Stack
          spacing={2}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <FormProvider
            methods={methods}
            onSubmit={handleSubmit(onVerifyCode)}
          >
            <Stack
              spacing={3}
              justifyContent={'center'}
              alignItems={'center'}
              mx={6.875}
            >
              <Box height='170px' />
              <Typography color={'#013D7C'} fontSize={16} fontWeight={600} textAlign='center'>
                Digite o Código do {pathname.includes('coupon') ? 'Cupom' : 'Viva Presente'}
              </Typography>
              <RHFTextField
                name="code"
                id="CODEFIELD"
                placeholder="- - - - - -"
                sx={{
                  maxWidth: '473px',
                  minWidth: isMobile ? '70vw' : '473px',
                  '& .MuiInputBase-input': {
                    textAlign: 'center',
                  },
                }}
              />
              <ContainedLoadingButton
                sx={{ maxWidth: '473px', maxHeight: '46px', minWidth: isMobile ? '100%' : '473px', py: '11px', px: '22px' }}
                isLoading={isLoading}
                title="Consultar"
                backgroundColor={'#013D7C'}
                type="submit"
                disabled={values.code.length < 1 ? true : false}
              />
              {showInfo ?
                <>
                  <Box
                    p={5}
                    bgcolor='#01254B'
                    width='100%'
                    height='100vh'
                    // borderRadius='80px 80px 0px 0px'
                    position='fixed'
                    zIndex={800}
                    justifyContent='center'
                    alignItems='center'
                    display='flex'
                    bottom={0}
                    sx={{
                      opacity: 0.75
                    }}
                  />
                  <Box
                    p={5}
                    bgcolor='#F2F2F2'
                    width='100%'
                    borderRadius='80px 80px 0px 0px'
                    position='fixed'
                    zIndex={1000}
                    justifyContent='center'
                    alignItems='center'
                    display='flex'
                    bottom={0}
                    sx={{
                      opacity: '1 !important'
                    }}
                  >
                    <Stack spacing={2} width='100%'>
                      <Typography variant='h6' color='primary.main' textAlign='center'>
                        Verifique os Dados
                      </Typography>
                      <VerificationForm
                        isCoupon={pathname.includes('coupon')}
                        infos={infoDetails}
                        isLoadingSave={isLoadingRedeem}
                        onSave={() => handleRedeem()}
                        onCancel={() => handleCancelVerify()}
                      />
                    </Stack>

                  </Box>
                </>
                :
                isLoadingInfo ?
                  <Box
                    p={5}
                    bgcolor='#01254B'
                    width='100%'
                    height='100vh'
                    position='fixed'
                    zIndex={800}
                    justifyContent='center'
                    alignItems='center'
                    display='flex'
                    bottom={0}
                    sx={{
                      opacity: 0.75
                    }}
                  >
                    <LoadingIcon/>
                  </Box>
                  :
                  <></>

              }
              <SuccessModal isCoupon={pathname.includes('coupon')} isOpen={success} onGoToStart={handleGoToStart} />

            </Stack>
          </FormProvider>
        </Stack>
      </Stack>
    </Page>
  )
}