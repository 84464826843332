import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
// hooks
// ----------------------------------------------------------------------

type PromoterGuardProps = {
  children: ReactNode;
};

export default function PromoterGuard({ children }: PromoterGuardProps) {
  if (!localStorage.getItem('@cnPromoter-name')) {
    return <Navigate to={'/promoter/login'} />
  }

  return <>{children}</>;
}
