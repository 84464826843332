import { useEffect } from "react";
import { useLocation } from "react-router";
import { getCollectibles, getCoupons } from "src/redux/slices/store";
import { useDispatch } from "src/redux/store";

export default function useConsultList() {
    const dispatch = useDispatch()
    const { pathname } = useLocation()
  
    useEffect(() => {
        if(pathname.includes('coupon')){
            dispatch(getCoupons())
        }else{
            dispatch(getCollectibles())
        }
    },[pathname, dispatch])
    
    return {
      
    }
  }
  