import { LoadingButton } from "@mui/lab";
import { Button, Stack, TextField } from "@mui/material";

type Props = {
    isCoupon: boolean;
    infos: {
        name: string;
        reservedDate: string;
        clientName: string;
        clientPhone: string
    };
    isLoadingSave: boolean;
    onSave: () => void;
    onCancel: () => void;
}

export default function VerificationForm({
    isCoupon,
    infos,
    isLoadingSave,
    onSave,
    onCancel
}: Props){
    return(
        <Stack spacing={5}>
            <Stack spacing={3}>
                <TextField
                    sx={{
                        '& .MuiInputLabel-root': {
                            color: (theme) => theme.palette.primary.main,
                        },
                        '& input': {
                            color: (theme) => theme.palette.primary.main,
                            '-webkit-text-fill-color': (theme) => `${theme.palette.primary.main} !important`,
                        },
                    }}
                    label={isCoupon ? 'Nome do Cupom' : 'Nome do Viva Presente'}
                    value={infos?.name}
                    disabled
                />

                <TextField
                    sx={{
                        '& .MuiInputLabel-root': {
                            color: (theme) => theme.palette.primary.main,
                        },
                        '& input': {
                            color: (theme) => theme.palette.primary.main,
                            '-webkit-text-fill-color': (theme) => `${theme.palette.primary.main} !important`,
                        },
                    }}
                    label={'Data da Reserva'}
                    value={infos?.reservedDate}
                    disabled
                />

                <TextField
                    sx={{
                        '& .MuiInputLabel-root': {
                            color: (theme) => theme.palette.primary.main,
                        },
                        '& input': {
                            color: (theme) => theme.palette.primary.main,
                            '-webkit-text-fill-color': (theme) => `${theme.palette.primary.main} !important`,
                        },
                    }}
                    label={'Nome do Cliente'}
                    value={infos?.clientName}
                    disabled
                />

                <TextField
                    sx={{
                        '& .MuiInputLabel-root': {
                            color: (theme) => theme.palette.primary.main,
                        },
                        '& input': {
                            color: (theme) => theme.palette.primary.main,
                            '-webkit-text-fill-color': (theme) => `${theme.palette.primary.main} !important`,
                        },
                    }}
                    label={'Celular do Cliente'}
                    value={infos?.clientPhone}
                    disabled
                />
            </Stack>
            <Stack direction='row' alignItems='center' justifyContent='space-between'>
                <Button
                    onClick={onCancel}
                    sx={{
                        borderRadius: 5
                    }}
                >
                    Cancelar
                </Button>
                <LoadingButton
                    onClick={onSave}
                    loading={isLoadingSave}
                    variant='contained'
                    sx={{
                        borderRadius: 5
                    }}
                >
                    Salvar
                </LoadingButton>
            </Stack>
        </Stack>
    )
}