import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
import axios from '../../utils/axios';
import { get, post } from '../slices/api';
import { AxiosError } from 'axios';

type BonusState = {
  isLoading: boolean,
  error: any,
  data: any,
  name: any
  pin: any
  bonusValue: any,
  isError: boolean,
  description: string,
  title: string,
}

const initialState: BonusState = {
  isLoading: false,
  error: null,
  data: null,
  name: null,
  pin: null,
  bonusValue: null,
  isError: false,
  description: '',
  title: '',
};

const slice = createSlice({
  name: 'bonus',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    },
    setModalInfos(state, action) {
      state.description = action.payload.description;
      state.title = action.payload.title;
      state.isError = action.payload.isError;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setPhoneFormData(state, action) {
      action.payload.check = ['acceptedTerms', 'acceptedEmail', 'acceptedSMS', 'acceptedWhatsapp']
      console.log("setPhoneFormData: ", action.payload)
      // state.codeFormData = action.payload
    }
  },
});

export default slice.reducer;

export const { setPhoneFormData } = slice.actions;

export function createBonus(pin: string, bonusValue: string, phone: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await post('/promoter/create-bonus', { "pin": pin, "bonusValue": bonusValue, "phone": phone });
      dispatch(slice.actions.stopLoading());
      dispatch(slice.actions.setModalInfos(
        {
          title: "Bônus gerado com sucesso!",
          description: "Informe para o cliente confirmar o recebimento do SMS com valor de bônus gerado e a data de validade para o uso.",
          isError: false
        }
      ));
      return true
    } catch (error) {
      dispatch(slice.actions.stopLoading());

      if (error instanceof AxiosError) {
        if(error.response?.status == 400){
          const message = error.response?.data?.message;
          if (message == 'exceedLimit') {
            dispatch(slice.actions.setModalInfos(
              {
                title: "Valor inserido acima do limite permitido.",
                description: "Você pode inserir bônus de até R$ 500,00. Tente novamente seguindo as regras da campanha.",
                isError: true
              }
            ));
          }
          if(message === "minusThanLimit"){
            dispatch(slice.actions.setModalInfos(
              {
                title: "Valor inserido abaixo do limite permitido.",
                description: "Você pode inserir bônus a partir de R$ 10. Tente novamente seguindo as regras da campanha. ",
                isError: true
              }
            ));
          }
          if(message === 'invalidPin'){
            dispatch(slice.actions.setModalInfos(
              {
                title: "PIN inválido!",
                description: "Confira o número do PIN recebido pelo cliente e digite novamente.",
                isError: true
              }
            ));    
          }
        }else if(error.response?.status == 404 && error.response?.data?.message == 'userNotRegisteredInViva'){
          dispatch(slice.actions.setModalInfos(
            {
              title: "Cliente não cadastrado na Viva.",
              description: "Informe para o cliente que ele precisa se cadastrar na Viva para receber o bônus.",
              isError: true
            }
          ));
        } else {
          dispatch(slice.actions.setModalInfos(
            {
              title: "Error no servidor",
              description: "Tente novamente mais tarde.",
              isError: true
            }
          ));
        }
      } else {
        dispatch(slice.actions.setModalInfos(
          {
            title: "Error no servidor",
            description: "Tente novamente mais tarde.",
            isError: true
          }
        ));
      }
      return error?.message ?? 'error'; // A principio isso não serve pra nada
    }
  };
}

export function resendPin(phone: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await post('/auth/resendPin', { phone });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function verifyPin(pinCode: string, phone: string, check: string[], termsVersion: string, token: string | null) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      var response = await axios.post('/auth/validatePin', { phone, pinCode, check, termsVersion, token });
      return response.data
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error
    }
  };
}