import * as Yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { manualInsert } from "src/redux/slices/store";
import { useDispatch } from "src/redux/store";
import { validateCPF } from 'src/utils/validations';

type FormValuesProps = {
  clientCpf: any;
  clientPhone: any;
  storeName: string;
  insertionType: any;
  insertionReason: any;
};

export default function useManualInsert() {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const [ success, setSuccess ] = useState(false)

  const StoreSchema = Yup.object().shape({
    clientCpf: Yup.string().test("cpf", "CPF inválido", (val) => validateCPF(val) ),
    clientPhone: Yup.string().min(15, 'Número inválido'),
    storeName: Yup.string().required('Campo obrigatório'),
    insertionType: Yup.array().min(1, 'Selecione uma opção').required('Campo obrigatório'),
    insertionReason: Yup.array().min(1, 'Selecione uma opção').required('Campo obrigatório'),
	});

	const defaultValues = useMemo(() =>(
      {
        clientCpf: '',
        clientPhone: '',
        storeName: '',
        insertionType: [],
        insertionReason: [],
      }
    ),[]);

	const methods = useForm<FormValuesProps>({
		resolver: yupResolver(StoreSchema),
		defaultValues: defaultValues
	});

  const {
		watch,
		handleSubmit,
    reset,
		formState: { errors, isSubmitting },
	} = methods;

  const values = watch()

  const onSubmit = async (data: FormValuesProps) => {
    var body = {...data}
    if(body.insertionReason.length > 1){
      body.insertionReason = body.insertionReason.join(': ')
    }
    try{
      await dispatch(manualInsert(body))
      setSuccess(true)
    }catch(e){
      enqueueSnackbar(e.response?.data?.message || e.message, { variant: 'error' })
    }
  }

  const handleGoToStart = () => {
    window.location.reload()
  }

  useEffect(() => {
    if(defaultValues){
      reset(defaultValues)
    }else{
      reset()
    }
  },[defaultValues, reset])

  return {
      values,
      errors,
      methods,
      success,
      isSubmitting,
      handleSubmit,
      handleGoToStart,
      onSubmit
  }
}
  