import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// import axios from '../../utils/axios';
import { get, post } from './api';

type ClientState = {
  isLoading: boolean,
  error: any,
  experienceList: {id: string, name: string, image: string}[],
  experience: any,
}

const initialState: ClientState = {
  isLoading: false,
  error: null,
  experienceList: [],
  experience: null
};

const slice = createSlice({
  name: 'experiences',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getExperiencesSuccess(state, action){
      state.isLoading = false;
      state.experienceList = action.payload;
    },

    getExperienceSuccess(state, action){
      state.experience = action.payload;
      state.isLoading = false;
    }
  },
});

export default slice.reducer;

export function getExperiences() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try{
      const experiences = await get('/promoter/experience-list');
      dispatch(slice.actions.getExperiencesSuccess(experiences.data));
    }catch(e){
      console.log(e)
      dispatch(slice.actions.hasError(e))
    }
  };
}

export function getExperience(experienceId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try{
      const experience = await get(`/promoter/experience-detail/${experienceId}`);
      dispatch(slice.actions.getExperienceSuccess(experience.data));
    }catch(e){
      console.log(e)
      dispatch(slice.actions.hasError(e))
    }
  };
}

export function addMaxParticipants(experienceId: string, time: string, numberToAdd: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try{
      await post(`/promoter/experience-add-max-participants`,{experienceId, time, numberToAdd});
      dispatch(slice.actions.stopLoading());
    }catch(e){
      dispatch(slice.actions.stopLoading());
      throw e
    }
  };
}

export function addExperienceAccess(experienceId: string, date: string, userName: string, userCellphone: string, adults: number, kids: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try{
      await post(`/promoter/experience-access`, 
        {
          experienceId, 
          userName, 
          userCellphone, 
          date,
          adults,
          kids
        });
      dispatch(slice.actions.stopLoading());
    }catch(e){
      dispatch(slice.actions.stopLoading());
      throw e
    }
  };
}