import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, Drawer, Button, Typography, IconButton } from '@mui/material';
// hooks

// utils
// config
// components
//
import navConfig from './NavConfig';
import useResponsive from 'src/hooks/useResponsive';
import useCollapseDrawer from 'src/hooks/useCollapseDrawer';
import { NAVBAR } from 'src/config';
import Scrollbar from 'src/components/Scrollbar';
import { NavSectionVertical } from 'src/components/nav-section';
import Iconify from 'src/components/Iconify';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

// ----------------------------------------------------------------------

type Props = {
  isOpenSidebar: boolean;
  onCloseSidebar: VoidFunction;
};

export default function NavbarVertical({ isOpenSidebar, onCloseSidebar }: Props) {

  const handleLogout = () => {
    localStorage.removeItem('@cnStore-name');
    localStorage.removeItem('@cnStore-accessToken');
    localStorage.removeItem('@cnStore-refreshToken');
    window.location.href = '/store';
  }

  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  const { isCollapse, collapseClick, onHoverEnter, onHoverLeave } =
    useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }} 
    >
      <Stack height='100%' pb={10} px={2}>
        <Stack direction='row' py={2} pl={0.5} pr={1} alignItems='center' justifyContent='space-between'>
          <Typography variant='h6'>
            Menu
          </Typography>
          <IconButton
            onClick={onCloseSidebar}
          >
            <Iconify icon='ph:x' width={18} height={18}/>
          </IconButton>
        </Stack>
        
        <NavSectionVertical navConfig={navConfig} isCollapse={isCollapse} />

        <Box sx={{ flexGrow: 1 }} />

        <Button
          sx={{
            color:'#2B3B4B',
          }}
          startIcon={
            <Iconify icon='ic:round-logout'/> 
          }
          onClick={handleLogout}
        >
          Sair
        </Button>
      </Stack>
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH : NAVBAR.DASHBOARD_WIDTH,
        },
        ...(collapseClick && {
          position: 'absolute',
        }),
      }}
    >
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{ 
            sx: { 
              width: NAVBAR.DASHBOARD_WIDTH,
              borderRadius: '0px 0px 0px 80px'
            },
          }}
          anchor='right'
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          anchor='right'
          open={isOpenSidebar}
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{ 
            sx: { 
              width: NAVBAR.DASHBOARD_WIDTH,
              borderRadius: '0px 0px 0px 80px'
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
