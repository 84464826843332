import { Stack, Typography, Box, IconButton } from "@mui/material";
import Menu from "src/components/icons/MenuIcon";
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import NavbarVertical from "./navbar/NavbarVertical";
import { useState } from "react";
import Iconify from "src/components/Iconify";

export default function StoreLayout(){
  const [ isOpenSidebar, setIsOpenSidebar ] = useState(false)
  const { pathname } = useLocation()
  const navigate = useNavigate()

  return(
    <>
      <Stack px={3} top={0} height={52} width='100%' justifyContent='space-between' direction='row' bgcolor='#18417F' alignItems='center' zIndex={'700'} position='fixed'>
        {pathname === '/store/area' ?
          <Typography fontSize={14} fontWeight={600} lineHeight='22px' color={"#fff"} textAlign={'left'} justifyContent='center'>
            {localStorage.getItem('@cnStore-name')}
          </Typography>
          :
          <IconButton
            onClick={() => navigate('/store/area')}
          >
            <Iconify icon='ep:arrow-left-bold' height={18} width={18} color='white'/>
          </IconButton>
        }
        <Menu onClick={() => setIsOpenSidebar(true)}/>
      </Stack>
      <Box>
        <Outlet/>
      </Box>
      <NavbarVertical isOpenSidebar={isOpenSidebar} onCloseSidebar={() => setIsOpenSidebar(false)} />
    </>
  )
}