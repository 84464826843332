import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
import axios from '../../utils/axios';
import { get } from './api';

type PromoterState = {
  isLoading: boolean,
  error: any,
  data: any,
  codeFormData: any,
  isError: boolean,
  description: string,
  title: string,
}

const initialState: PromoterState = {
  isLoading: false,
  error: null,
  data: null,
  codeFormData: null,
  isError: false,
  description: '',
  title: '',
};

const slice = createSlice({
  name: 'promoter',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    },
    setModalInfos(state, action) {
      state.description = action.payload.description;
      state.title = action.payload.title;
      state.isError = action.payload.isError;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
    },

    setPhoneFormData(state, action) {
      state.codeFormData = action.payload
    }
  },
});

export default slice.reducer;

export const { setPhoneFormData } = slice.actions;

export function getInfos() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      var response = await get('/promoter/info');
      dispatch(slice.actions.stopLoading());
      return response.data
    } catch (error) {
      return error.message
    }
  };
}

export function verifyPromoter(code: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      var response = await axios.post('/promoter/verify', { "promoterCode": code });
      dispatch(slice.actions.stopLoading());
      return response.data
    } catch (error) {
      dispatch(slice.actions.setModalInfos(
        {
          title: "Código invalido. Digite outro código para acessar.",
          description: "",
          isError: true
        }
      ));
      dispatch(slice.actions.hasError(error));
    }
  };
}
