import { Typography, Stack, Box, Button } from "@mui/material";
import Page from "src/components/Page";
import { FormProvider, RHFMaskField, RHFMultiCheckbox, RHFTextField } from "src/components/hook-form";
import { LoadingButton } from "@mui/lab";
import useManualInsert from "../../hooks/useManualInsert";
import { useNavigate } from "react-router-dom";
import { useSelector } from "src/redux/store";
import SuccessModal from "../components/SuccessModal";



export default function ManualInsert() {
  const navigate = useNavigate()
  const {
    methods,
    success,
    onSubmit,
    handleSubmit,
    handleGoToStart,
  } = useManualInsert();
  const { isLoadingRedeem } = useSelector((state) => state.store)

  return (
    <Page title='Liberação Manual'>
      <Stack pb={10}>
        <Box
          mt='52px'
          p={5}
          bgcolor='#F2F2F2'
          width='100vw'
          borderRadius='0px 0px 80px 80px'
          position='fixed'
          zIndex={90}
          justifyContent='center'
          alignItems='center'
          display='flex'
        >
          <Typography variant='h6' color='primary.main'>
            Liberação Manual
          </Typography>
        </Box>
        <Stack
          spacing={2}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <FormProvider
            methods={methods}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Stack
              spacing={3}
              justifyContent={'center'}
              alignItems={'center'}
              mx={6.875}
            >
              <Box height='170px' />
              <RHFMaskField
                name="clientCpf"
                mask="999.999.999-99"
                label="CPF"
              />
              <RHFMaskField
                name="clientPhone"
                mask="(99) 99999-9999"
                label="Telefone"
              />
              <RHFTextField
                name='storeName'
                label='Loja'
              />
              <Stack spacing={5} direction='row' justifyContent='start' width='100%'>
                <Typography fontSize={16} fontWeight={400} color='#013D7C'>
                  Tipo:
                </Typography>
                <RHFMultiCheckbox
                  name="insertionType"
                  options={[
                    {
                      label: 'Cupom',
                      value: 'coupon'
                    },
                    {
                      label: 'Produto',
                      value: 'product'
                    }
                  ]}
                />
              </Stack>
              <Stack spacing={2.625} direction='row' justifyContent='start' width='100%'>
                <Typography fontSize={16} fontWeight={400} color='#013D7C'>
                  Motivo:
                </Typography>
                <RHFMultiCheckbox
                  name="insertionReason"
                  options={[
                    {
                      label: 'QR code ou código inválido',
                      value: 'QR code ou código inválido'
                    },
                    {
                      label: 'Problema com a internet',
                      value: 'Problema com a internet'
                    },
                    {
                      label: 'Problema com o aparelho',
                      value: 'Problema com o aparelho'
                    },
                    {
                      label: 'Outro:',
                      value: 'Outro'
                    },
                  ]}
                />
              </Stack>
              <Stack direction='row' alignItems='center' justifyContent='space-between' width='100%'>
                <Button
                  onClick={() => navigate('/store/area')}
                  sx={{
                    borderRadius: 5
                  }}
                >
                  Cancelar
                </Button>
                <LoadingButton
                  loading={isLoadingRedeem}
                  type='submit'
                  variant='contained'
                  sx={{
                    borderRadius: 5
                  }}
                >
                  Salvar
                </LoadingButton>
              </Stack>
            </Stack>
          </FormProvider>
        </Stack>
      </Stack>
      <SuccessModal isManualInsertion isOpen={success} onGoToStart={handleGoToStart} isCoupon={false}/>
    </Page>
  )
}